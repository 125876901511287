<template>
  <section>
    <AnexarArquivos />
    <SectionTitle title="Anamnese oftalmológica completa" />
      <PreAnamneseForm />
      <QueixaPrincipalForm />
      <AnamneseForm />
      <HistoriaDaDoencaAtualForm />
      <AntecedentesOftalmologicosForm />
      <AntecedentesPessoaisForm />
      <AntecedentesFamiliaresForm />
      <CirurgiasOftalmologicasAnterioresForm />
      <HabitosDevidaForm />
      <MedicamentosOftalmologicosEmUsoForm />
      <OutrosMedicamentosEmUsoForm />
      <AlergiasForm />
      <InformacoesAdicionaisAnamneseForm />

    <SectionTitle title="Triagem Benefício Visão" v-if="attendance?.attendance?.type === 'BV_EXAM'"/>
      <BvTriagemForm v-if="attendance?.attendance?.type === 'BV_EXAM'"/>
      <BvTesteAcuidadeVisualForm v-if="attendance?.attendance?.type === 'BV_EXAM'"/>
      <BvTesteIshiharaForm v-if="attendance?.attendance?.type === 'BV_EXAM'"/>

    <SectionTitle title="Anotações" v-if="attendance?.type === 'EXAM'"/>
      <InformacoesAdicionaisExamForm />

    <SectionTitle title="Exames oftalmológicos"/>
      <ExameFisicoForm />
      <EctoscopiaForm />
      <b-row>
        <AvbplAcuidadeVisualBinocularParaLongeForm />
        <AvbppAcuidadeVisualBinocularParaPertoForm />
      </b-row>
      <div id="module-wrapper-avsc-acuidade-visual-sem-correcao"/>
      <div id="module-wrapper-avcc-acuidade-visual-com-correcao"/>
        <b-row>
          <AvscAcuidadeVisualSemCorrecaoForm />
          <AvccAcuidadeVisualComCorrecaoForm />
        </b-row>
      <div id="module-wrapper-avppsc-acuidade-visual-para-perto-sem-correcao"/>
      <div id="module-wrapper-avppcc-acuidade-visual-para-perto-com-correcao"/>
        <b-row>
          <AvppscAcuidadeVisualParaPertoSemCorrecaoForm />
          <AvppccAcuidadeVisualParaPertoComCorrecaoForm />
        </b-row>
      <AberrometriaForm />
      <LensometriaForm />
      <TonometriaForm />
      <RefracaoDinamicaForm />
      <RefracaoEstaticaForm />
      <AutorrefracaoDinamicaForm />
      <AutorrefracaoEstaticaForm />
      <div id="module-wrapper-acuidade-visual-para-longe-apos-refracao"/>
      <div id="module-wrapper-acuidade-visual-para-perto-apos-refracao"/>
        <b-row>
          <AcuidadeVisualParaLongeAposRefracaoForm />
          <AcuidadeVisualParaPertoAposRefracaoForm />
        </b-row>
      <BiomicroscopiaForm />
      <PressaoIntraocularForm />
      <PressaoIntraocularCorrigidaForm />
      <CurvaTensionalForm />
      <GonioscopiaForm />
      <EsquiascopiaForm />
      <MapeamentoDeRetinaForm />
      <MotilidadeOcularExtrinsecaForm />
      <CoverTestForm />
      <ReflexosForm />
      <InformacoesAdicionaisForm />
      <CampoConfrontacaoForm />
      <TesteSensibilidadeMimicaFacialForm />

    <SectionTitle title="Exames complementares"/>
      <BiometriaForm />
      <RetinografiaForm />
      <PaquimetriaForm />
      <CampoVisualForm />
      <OctTomografiaDeCoerenciaOpticaForm />
      <OctTomografiaDeCoerenciaOpticaNervoOpticoForm />
      <OctTomografiaDeCoerenciaOpticaSegmentoAnteriorForm />
      <OctATomografiaDeCoerenciaOpticaForm />
      <TopografiaForm />
      <TomografiaDeCorneaPentacamForm />
      <TomografiaDeCorneaGalileiForm />
      <MicroscopiaEspecularDeCorneaForm />
      <IndocianinografiaIcgForm />
      <AngiofluoresceinografiaAfgForm />
      <UsgOcularForm />
      <EletrorretinogramaErgDeCampoTotalForm />
      <ErgMultifocalForm />
      <PotencialVisualEvocadoPveForm />
      <PveVarreduraForm />
      <PotencialAcuidadeMacularForm />
      <CeratometriaForm />
      <AdditionalExam
        v-for="formName in additionalExams"
        :key="formName.id"
        :formName="formName"
      />

    <SectionTitle title="Informações cirúrgicas"/>
      <DadosDaCirurgiaForm />
      <AnestesiaForm />
      <ProdutosUtilizadosForm />
      <PatologiaASerTratadaForm />
      <AnotacaoCirurgicaForm />
      <IntercorrenciasForm />
    <SectionTitle title="Diagnóstico"/>
      <HipoteseDiagnosticaForm />
      <CidForm />

    <SectionTitle title="Conduta" />
      <ExamRequestForm />
      <ApaAvaliacaoPreAnestesicaFom />
      <SolicitacaoDeCirurgiaFom />
      <SolicitacaoDeTratamentoForm />
      <EncaminhamentoForm />
      <PrescricaoMedicamentosaForm />
      <OculosForm />
      <OculosExtraForm />
      <LentesDeContatoForm />
      <OrientacaoPosOperatoriaFom />
      <MaisInformacoesForm />
      <RetornoForm />
      <AtestadosMedicosForm />
      <LaudoMedicoForm />
      <ImagensExamesEssilor />
      <ComplementoGlaucomaForm />
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    SectionTitle: () => import('@/components/Attendance/Forms/SectionTitle'),
    PreAnamneseForm: () => import('./Modules/PreAnamneseForm'),
    QueixaPrincipalForm: () => import('@/components/Attendance/Forms/Modules/QueixaPrincipalForm'),
    AnamneseForm: () => import('@/components/Attendance/Forms/Modules/AnamneseForm'),
    HistoriaDaDoencaAtualForm: () => import('@/components/Attendance/Forms/Modules/HistoriaDaDoencaAtualForm'),
    AntecedentesOftalmologicosForm: () => import('@/components/Attendance/Forms/Modules/AntecedentesOftalmologicosForm'),
    AntecedentesPessoaisForm: () => import('@/components/Attendance/Forms/Modules/AntecedentesPessoaisForm'),
    AntecedentesFamiliaresForm: () => import('@/components/Attendance/Forms/Modules/AntecedentesFamiliaresForm'),
    CirurgiasOftalmologicasAnterioresForm: () => import('@/components/Attendance/Forms/Modules/CirurgiasOftalmologicasAnterioresForm'),
    HabitosDevidaForm: () => import('@/components/Attendance/Forms/Modules/HabitosDevidaForm'),
    MedicamentosOftalmologicosEmUsoForm: () => import('@/components/Attendance/Forms/Modules/MedicamentosOftalmologicosEmUsoForm'),
    OutrosMedicamentosEmUsoForm: () => import('@/components/Attendance/Forms/Modules/OutrosMedicamentosEmUsoForm'),
    AlergiasForm: () => import('@/components/Attendance/Forms/Modules/AlergiasForm'),
    InformacoesAdicionaisAnamneseForm: () => import('./Modules/InformacoesAdicionaisAnamneseForm'),
    ExameFisicoForm: () => import('@/components/Attendance/Forms/Modules/ExameFisicoForm'),
    EctoscopiaForm: () => import('@/components/Attendance/Forms/Modules/EctoscopiaForm'),
    AvscAcuidadeVisualSemCorrecaoForm: () => import('@/components/Attendance/Forms/Modules/AvscAcuidadeVisualSemCorrecaoForm'),
    AvccAcuidadeVisualComCorrecaoForm: () => import('@/components/Attendance/Forms/Modules/AvccAcuidadeVisualComCorrecaoForm'),
    AvppscAcuidadeVisualParaPertoSemCorrecaoForm: () => import('@/components/Attendance/Forms/Modules/AvppscAcuidadeVisualParaPertoSemCorrecaoForm'),
    AvppccAcuidadeVisualParaPertoComCorrecaoForm: () => import('@/components/Attendance/Forms/Modules/AvppccAcuidadeVisualParaPertoComCorrecaoForm'),
    RefracaoDinamicaForm: () => import('@/components/Attendance/Forms/Modules/RefracaoDinamicaForm'),
    RefracaoEstaticaForm: () => import('@/components/Attendance/Forms/Modules/RefracaoEstaticaForm'),
    AutorrefracaoDinamicaForm: () => import('@/components/Attendance/Forms/Modules/AutorrefracaoDinamicaForm'),
    AutorrefracaoEstaticaForm: () => import('@/components/Attendance/Forms/Modules/AutorrefracaoEstaticaForm'),
    AcuidadeVisualParaLongeAposRefracaoForm: () => import('@/components/Attendance/Forms/Modules/AcuidadeVisualParaLongeAposRefracaoForm'),
    AcuidadeVisualParaPertoAposRefracaoForm: () => import('@/components/Attendance/Forms/Modules/AcuidadeVisualParaPertoAposRefracaoForm'),
    BiomicroscopiaForm: () => import('@/components/Attendance/Forms/Modules/BiomicroscopiaForm'),
    PressaoIntraocularForm: () => import('@/components/Attendance/Forms/Modules/PressaoIntraocularForm'),
    CurvaTensionalForm: () => import('@/components/Attendance/Forms/Modules/CurvaTensionalForm'),
    GonioscopiaForm: () => import('@/components/Attendance/Forms/Modules/GonioscopiaForm'),
    EsquiascopiaForm: () => import('@/components/Attendance/Forms/Modules/EsquiascopiaForm'),
    MapeamentoDeRetinaForm: () => import('@/components/Attendance/Forms/Modules/MapeamentoDeRetinaForm'),
    MotilidadeOcularExtrinsecaForm: () => import('@/components/Attendance/Forms/Modules/MotilidadeOcularExtrinsecaForm'),
    CoverTestForm: () => import('@/components/Attendance/Forms/Modules/CoverTestForm'),
    ReflexosForm: () => import('@/components/Attendance/Forms/Modules/ReflexosForm'),
    InformacoesAdicionaisForm: () => import('@/components/Attendance/Forms/Modules/InformacoesAdicionaisForm'),
    BiometriaForm: () => import('@/components/Attendance/Forms/Modules/BiometriaForm'),
    RetinografiaForm: () => import('@/components/Attendance/Forms/Modules/RetinografiaForm'),
    PaquimetriaForm: () => import('@/components/Attendance/Forms/Modules/PaquimetriaForm'),
    CampoVisualForm: () => import('@/components/Attendance/Forms/Modules/CampoVisualForm'),
    OctTomografiaDeCoerenciaOpticaForm: () => import('@/components/Attendance/Forms/Modules/OctTomografiaDeCoerenciaOpticaForm'),
    OctTomografiaDeCoerenciaOpticaNervoOpticoForm: () => import('@/components/Attendance/Forms/Modules/OctTomografiaDeCoerenciaOpticaNervoOpticoForm'),
    OctTomografiaDeCoerenciaOpticaSegmentoAnteriorForm: () => import('@/components/Attendance/Forms/Modules/OctTomografiaDeCoerenciaOpticaSegmentoAnteriorForm'),
    OctATomografiaDeCoerenciaOpticaForm: () => import('@/components/Attendance/Forms/Modules/OctATomografiaDeCoerenciaOpticaForm'),
    TopografiaForm: () => import('@/components/Attendance/Forms/Modules/TopografiaForm'),
    TomografiaDeCorneaPentacamForm: () => import('@/components/Attendance/Forms/Modules/TomografiaDeCorneaPentacamForm'),
    TomografiaDeCorneaGalileiForm: () => import('@/components/Attendance/Forms/Modules/TomografiaDeCorneaGalileiForm'),
    MicroscopiaEspecularDeCorneaForm: () => import('@/components/Attendance/Forms/Modules/MicroscopiaEspecularDeCorneaForm'),
    IndocianinografiaIcgForm: () => import( '@/components/Attendance/Forms/Modules/IndocianinografiaIcgForm'),
    AngiofluoresceinografiaAfgForm: () => import('@/components/Attendance/Forms/Modules/AngiofluoresceinografiaAfgForm'),
    UsgOcularForm: () => import('@/components/Attendance/Forms/Modules/UsgOcularForm'),
    EletrorretinogramaErgDeCampoTotalForm: () => import('@/components/Attendance/Forms/Modules/EletrorretinogramaErgDeCampoTotalForm'),
    ErgMultifocalForm: () => import('@/components/Attendance/Forms/Modules/ErgMultifocalForm'),
    PotencialVisualEvocadoPveForm: () => import('@/components/Attendance/Forms/Modules/PotencialVisualEvocadoPveForm'),
    PveVarreduraForm: () => import('@/components/Attendance/Forms/Modules/PveVarreduraForm'),
    PotencialAcuidadeMacularForm: () => import('@/components/Attendance/Forms/Modules/PotencialAcuidadeMacularForm'),
    DadosDaCirurgiaForm: () => import('@/components/Attendance/Forms/Modules/DadosDaCirurgiaForm'),
    AnestesiaForm: () => import('@/components/Attendance/Forms/Modules/AnestesiaForm'),
    ProdutosUtilizadosForm: () => import('@/components/Attendance/Forms/Modules/ProdutosUtilizadosForm'),
    PatologiaASerTratadaForm: () => import('@/components/Attendance/Forms/Modules/PatologiaASerTratadaForm'),
    AnotacaoCirurgicaForm: () => import('@/components/Attendance/Forms/Modules/AnotacaoCirurgicaForm'),
    IntercorrenciasForm: () => import('@/components/Attendance/Forms/Modules/IntercorrenciasForm'),
    HipoteseDiagnosticaForm: () => import('@/components/Attendance/Forms/Modules/HipoteseDiagnosticaForm'),
    CidForm: () => import('@/components/Attendance/Forms/Modules/CidForm'),
    ApaAvaliacaoPreAnestesicaFom: () => import('@/components/Attendance/Forms/Modules/ApaAvaliacaoPreAnestesicaFom'),
    SolicitacaoDeCirurgiaFom: () => import('@/components/Attendance/Forms/Modules/SolicitacaoDeCirurgiaFom'),
    ExamRequestForm: () => import("@/components/Attendance/Forms/Modules/ExamRequest/ExamRequestForm"),
    SolicitacaoDeTratamentoForm: () => import('@/components/Attendance/Forms/Modules/SolicitacaoDeTratamentoForm'),
    EncaminhamentoForm: () => import('@/components/Attendance/Forms/Modules/EncaminhamentoForm'),
    PrescricaoMedicamentosaForm: () => import('@/components/Attendance/Forms/Modules/PrescricaoMedicamentosaForm'),
    OculosForm: () => import('@/components/Attendance/Forms/Modules/OculosForm'),
    LentesDeContatoForm: () => import('@/components/Attendance/Forms/Modules/LentesDeContatoForm'),
    OrientacaoPosOperatoriaFom: () => import('@/components/Attendance/Forms/Modules/OrientacaoPosOperatoriaFom'),
    MaisInformacoesForm: () => import('@/components/Attendance/Forms/Modules/MaisInformacoesForm'),
    RetornoForm: () => import('@/components/Attendance/Forms/Modules/RetornoForm'),
    AtestadosMedicosForm: () => import('@/components/Attendance/Forms/Modules/AtestadosMedicosForm'),
    LaudoMedicoForm: () => import('@/components/Attendance/Forms/Modules/LaudoMedicoForm'),
    LensometriaForm: () => import('@/components/Attendance/Forms/Modules/LensometriaForm'),
    AdditionalExam: () => import('./Modules/AdditionalExam'),
    CeratometriaForm: () => import('./Modules/CeratometriaForm'),
    AnexarArquivos: () => import('./Modules/AnexarArquivos'),
    TonometriaForm: () => import('./Modules/TonometriaForm'),
    InformacoesAdicionaisExamForm: () => import('@/components/Attendance/Forms/Modules/InformacoesAdicionaisExamForm'),
    BvTriagemForm: () => import('@/components/Attendance/Forms/Modules/BvTriagemForm'),
    BvTesteAcuidadeVisualForm: () => import('@/components/Attendance/Forms/Modules/BvTesteAcuidadeVisualForm'),
    BvTesteIshiharaForm: () => import('@/components/Attendance/Forms/Modules/BvTesteIshiharaForm'),
    CampoConfrontacaoForm: () => import('@/components/Attendance/Forms/Modules/CampoConfrontacaoForm'),
    TesteSensibilidadeMimicaFacialForm: () => import('@/components/Attendance/Forms/Modules/TesteSensibilidadeMimicaFacialForm'),
    AvbplAcuidadeVisualBinocularParaLongeForm: () => import('@/components/Attendance/Forms/Modules/AvbplAcuidadeVisualBinocularParaLongeForm.vue'),
    AvbppAcuidadeVisualBinocularParaPertoForm: () => import('@/components/Attendance/Forms/Modules/AvbppAcuidadeVisualBinocularParaPertoForm.vue'),
    OculosExtraForm: () => import('@/components/Attendance/Forms/Modules/OculosExtraForm'),
    PressaoIntraocularCorrigidaForm: () => import('@/components/Attendance/Forms/Modules/PressaoIntraocularCorrigidaForm'),
    AberrometriaForm: () => import('@/components/Attendance/Forms/Modules/AberrometriaForm'),
    ImagensExamesEssilor: () => import('@/components/Attendance/Forms/Modules/ImagensExamesEssilor'),
    ComplementoGlaucomaForm: () => import('@/components/Attendance/Forms/Modules/ComplementoGlaucomaForm'),
  },
  data() {
    return {
      tabs: [],
      currentTabIndex: 0,
      tabItems: [
        ['SolicExam', 'Solicitação de Exames'],
        ['PacExam', 'Pacotes de Exames']
      ],
    }
  },
  computed: {
    ...mapGetters('attendance/form', ['additionalExams']),
    ...mapState({
      attendance: state => state.attendance,
    }),
  },

  beforeMount() {
    this.tabItems = new Map(this.tabItems)
    this.tabs     = Array.from(this.tabItems.values())
  },
  methods: {
    onChangeTab(index) {
      this.currentTabIndex = index
    },
  }
}
</script>
